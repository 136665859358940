jQuery(function(){
    jQuery("#open-fancybox").fancybox({
        modal: true,
    });

    jQuery("#open-fancybox").click();

    jQuery("#disclaimer .btn-primary").click(function(e){
        $.fancybox.close();
        e.preventDefault();
    });
});