jQuery(function() {
    function pad (str, max) {
        str = str.toString();
        return str.length < max ? pad("0" + str, max) : str;
    }

    if(jQuery("#calendar").length) {
        // Get the element
        var element = document.getElementById("calendar");
        // Create the calendar
        var eventsCal = jsCalendar.new(element, "now", {
            navigator : true,
            navigatorPosition : "left",
            zeroFill : true,
            monthFormat : "month YYYY",
            dayFormat : "DDD",
            language : "en"
        });

        var events = jQuery.parseJSON(jQuery('#calendar').attr('data-events'));

        events.forEach(function(element, key) {
            eventsCal.select(element.date);
        });

        // Add events
        eventsCal.onDateClick(function(event, date){
            var d = date.getDate();
            var m =  date.getMonth();
            m += 1;  // JavaScript months are 0-11
            var y = date.getFullYear();

            var keydate = (pad(d, 2) + "/" + pad(m, 2) + "/" + y);

            events.forEach(function(element) {
                if(element.date === keydate) {
                    window.location.href = element.permalink;
                }
            });
        });
    }

    /*eventsCal.select([
        "01/01/2020",
        "02/06/2020",
        "03/07/2020",
        "05/08/2020"
    ]);*/
});